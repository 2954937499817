exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation) {
    // console.log('ADTEST: First load detected. Skipping ad refresh.')
    return
  }

  // console.log('ADTEST: Navigation detected. Refreshing ads...')
  if (window.googletag && window.googletag.pubads) {
    window.googletag.cmd.push(() => {
      // Clean up refresh timer
      if (window.gptRefreshTimer) {
        clearInterval(window.gptRefreshTimer)
        window.gptRefreshTimer = null
        // console.log('ADTEST: Cleaned up refresh timer on navigation')
      }

      // First destroy existing slots
      window.googletag.destroySlots()
      window.gptSlots = {}
      window.gptInitialized = false
      // console.log('ADTEST: Slots destroyed and reset')

      // Then allow new slots to be defined by DfpSlotsScript
      // The refresh will happen automatically when new slots are defined
    })
  }
}
